.react-datepicker__tab-loop {
    position: absolute;
    top: 0;
}

.react-datepicker__triangle {
    display: none !important;
}

.datePicker {
    width: 100%;
    position: absolute;
    top: 0;
}